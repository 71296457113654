<template>
    <div class="footer">
        <div class="left">
            <img class="logo" src="@/assets/images/pc/index/logo.png" />
            <div class="copyright-time">© 2018 - 2023 BitVenus</div>
            <div class="partner-list">
                <img v-for="(item,index) in partnerList" :key="index" class="partner-item" :src="item.pic" @click="toUrl(item.url)" />
            </div>
        </div>
        <div class="right">
            <div class="link-box">
                <div class="label">{{$t('Home.FooterItems1')[0]}}</div>
                <div class="link-item" @click="toArticle('rate_standard')">{{$t('Home.FooterItems1')[1]}}</div>
                <div class="link-item" @click="toArticle('risk_warn')">{{$t('Home.FooterItems1')[2]}}</div>
                <div class="link-item" @click="toArticle('fxq')">{{$t('Home.FooterItems1')[3]}}</div>
                <div class="link-item" @click="toArticle('about_us')">{{$t('Home.FooterItems1')[4]}}</div>
            </div>
            <div class="link-box">
                <div class="label">{{$t('Home.FooterItems2')[0]}}</div>
                <div class="link-item" @click="toPath('/ServiceTerms')">{{$t('Home.FooterItems2')[1]}}</div>
                <div class="link-item" @click="toPath('/PrivacyStatement')">{{$t('Home.FooterItems2')[2]}}</div>
                <div class="link-item" @click="toArticle('white_paper')">{{$t('Home.FooterItems2')[3]}}</div>
                <div class="link-item" @click="toArticle('msb_auth')">{{$t('Home.FooterItems2')[4]}}</div>
            </div>
            <div class="link-box">
                <div class="label">{{$t('Home.FooterItems3')[0]}}</div>
                <div class="link-item" @click="toArticle('new_guide')">{{$t('Home.FooterItems3')[1]}}</div>
                <div class="link-item" @click="toArticle('operate_guide')">{{$t('Home.FooterItems3')[2]}}</div>
                <div class="link-item" @click="toArticle('contact_us')">{{$t('Home.FooterItems3')[3]}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                partnerList: []
            }
        },
        created() {
            this.getPartnerList()
        },
        methods: {
            getPartnerList() {
                this.$api.setting({
                    slug: 'link'
                },300000).then(res => {
                    if(res.data) {
                        this.partnerList = JSON.parse(res.data)
                    }
                })
            },
            
            toUrl(url) {
                window.open(url)
            },
            
            toPath(path) {
                this.$router.push({
                    path
                })
            },
            
            toArticle(slug) {
                if(slug == 'white_paper') {
                    this.$api.setting({
                        slug
                    },300000).then(res => {
                        if(res.data) {
                            window.open(res.data)
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/article',
                        query: {
                            slug
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: $footer-height;
        box-sizing: border-box;
        padding: 40px $header-footer-padding 0;
        background-color: #000000;
        
        .left {
            min-width: 220px;
            max-width: 220px;
            margin-right: 120px;
            
            .logo {
                display: block;
                width: 100%;
            }
            
            .copyright-time {
                width: 100%;
                margin: 12px 0 25px;
                font-size: 15px;
                color: #fafafa;
                word-wrap: break-word;
            }
            
            .partner-list {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                
                .partner-item {
                    min-width: 22px;
                    max-width: 22px;
                    height: 22px;
                    margin: 0 20px 16px 0;
                    cursor: pointer;
                }
            }
        }
        
        .right {
            display: flex;
            max-width: 100%;
            
            .link-box {
                width: 220px;
                
                &:not(:last-child) {
                    margin-right: 40px;
                }
                
                .label {
                    max-width: 100%;
                    padding-top: 12px;
                    margin-bottom: 20px;
                    font-size: 15px;
                    color: #fafafa;
                    word-wrap: break-word;
                }
                
                .link-item {
                    max-width: 100%;
                    font-size: 13px;
                    color: #999999;
                    word-wrap: break-word;
                    cursor: pointer;
                    
                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
</style>